<template>
  <!-- <vue-countdown-timer
    :start-time="'2022/01/31 03:38:01 AM'"
    :end-time="'2022/01/31 04:40:01 PM'"
    :interval="1000"
    label-position="begin"
    :end-text="'Event ended!'"
    :day-txt="':'"
    :hour-txt="':'"
    :minutes-txt="':'"
    :seconds-txt="''"
  >
  </vue-countdown-timer> -->
  <vue-countdown-timer
    :start-time="'2022/01/31 03:38:01 AM'"
    :end-time="'2022/02/01 04:40:01 AM'"
    :end-text="'Event ended!'"
  >
    <template slot="countdown" slot-scope="scope">
      <span>{{ scope.props.days }}:</span>
      <span>{{ scope.props.hours }}:</span>
      <span>{{ scope.props.minutes }}:</span>
      <span>{{ scope.props.seconds }}</span>
    </template>

    <template slot="end-text" slot-scope="scope">
      <span style="color: green">{{ scope.props.endText }}</span>
    </template>
  </vue-countdown-timer>
</template>

<script>
// import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Test",
  components: {},
  methods: {},
  mounted() {},
  watch: {},
  async created() {},
};
</script>

<style></style>
